import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('answer') answer: ElementRef;
  private STORAGE_INDEX_NAME = 'QUEST_INDEX';
  public actualQuestIndex = 0;
  public text = '';
  public question = '';
  public loading = false;
  public wrongMessage = '';

  public quests = [{
    text: 'Ich habe gehört du wärst gerne ein Detektivin, ja dann viel Spass beim Lösen.',
    question: '4^4'
  }, {
    text: 'Zu einfach? Weiter gehts...',
    question: 'Was ist gelb, hat braune Punkte, einen langen Schwanz und hat eine Familie?'
  }, {
    text: 'Okay, jetzt brauchst du Hilfe von den Anderen',
    question: 'Welches Spiel spielt Jonas zur Zeit sehr oft?'
  }, {
    text: 'Na, wer hilft dir bei dieser Aufgabe?',
    question: 'Was ist die Ableitung von e^x dx?'
  }, {
    text: 'Nachname reicht aus. Google? Ja, mach das :P (Nachname reicht), <a href="http://lmgtfy.com/?q=e%5E(i*%CF%80)+%2B+0+%3D+-1" target="_blank">Hier der Link</a>',
    question: 'Von wem stammt diese Formel?  e^(i*π) + 0 = -1',
  }, {
    text: '',
    question: 'Was für eine Form ergibt die Formel von vorhin?',
  }, {
    text: 'Kannst du es erraten? Rate',
    question: 'Wohin gehen wir in die Ferien?',
  }, {
    text: 'Na leider verrate ich dir es noch nicht! Das wäre ja zu einfach.',
    question: 'Wie heisst das momentan meist gespielte Spiel (sehr beliebt bei jugendlichen)?',
  }, {
    text: 'Wechseln wir mal die Thematik.',
    question: 'Welche Ortschaft ist gesucht? (2 683 304 / 1 247 924), und ja sind keine normalen Koordinanten, auch die Schweiz hat ein System *hint* +!',
  }, {
    text: '',
    question: 'Warum kann "1 + 1 = 3" sein? (**** ist ...)',
  }, {
    text: '',
    question: `Fünf Häuser stehen nebeneinander. In ihnen wohnen Menschen von fünf unterschiedlichen Nationalitäten, die fünf unterschiedliche Getränke trinken, fünf unterschiedliche Zigarettenmarken rauchen und fünf unterschiedliche Haustiere haben.<br />
    Ausblenden<br />
    <br />
         Der Engländer lebt im roten Haus.<br />
         Der Spanier hat einen Hund.<br />
         Der Ukrainer trinkt gern Tee.<br />
         Das grüne Haus ist (direkt) links vom weißen Haus.<br />
         Im grünen Haus wird Kaffee getrunken.<br />
         Die Person, die Old-Gold raucht, hat eine Schnecke.<br />
         Der Bewohner des mittleren Hauses trinkt Milch.<br />
         Der Bewohner des gelben Hauses raucht Kools.<br />
         Der Norweger wohnt im ersten Haus.<br />
         Der Chesterfields-Raucher wohnt neben der Person mit der Fuchs.<br />
         Der Mann mit dem Pferd lebt neben der Person, die Kools raucht.<br />
         Der Lucky-Strike-Raucher trinkt Orangensaft.<br />
         Der Norweger wohnt neben dem blauen Haus.<br />
         Der Japaner raucht Parliaments.<br />
         Der Chesterfields-Raucher hat einen Nachbarn, der Wasser trinkt.<br />
    <br />
    Finden Sie heraus, wem das Zebra gehört!<br />
    <br />
    Anmerkungen: „Links“ und „Rechts“ sind von einem Betrachter aus zu verstehen, der vor den Häusern steht. Das erste Haus ist aus dieser Blickrichtung das Haus auf der linken Seite.<br />
    `,
  }, {
    text: 'Ach das war noch nichts.',
    question: `Gsuecht wird en 10 stelligi Zahl, die folgendi Bedingige erfüllt<br />
    <br />
         Alli Zifferä vo 0-9 chömed gnau einma vor.<br />
         Diä erste zwei Stellä sind dur 2 teilbar.<br />
         Diä erste 3 Stellä sind dur 3 teilbar.<br />
         .... <br />
         Diä erstä 10 Stelle sind dur 10 teilbar.<br />
    <br />`
  }, {
    text: 'Oh, da wird es langsam aber kompliziert?',
    question: `<p>Betrachten Sie die folgenden Zahlen genau. Jede Zeile ist nach einem bestimmten Verfahren aufgebaut.<br />
    <br />
    Können Sie sagen, wie die nächste Zeile, die Übernächste usw. aussehen?<br /><br />
        1      <br />
       1  1      <br />
       2  1      <br />
    1  2  1  1     <br />
    1  1  1  2  2  1<br />
    <br />
    Nächste Zeile ???</p>`
  }, {
    text: 'Das Reiseziel? Augen auf! Ich steh doch auf dem Briefumschlag!',
    question: ''
  }];



  public ngOnInit() {
    const i = localStorage.getItem(this.STORAGE_INDEX_NAME);

    if (i) {
      this.actualQuestIndex = Number.parseInt(i, 10);
    }

    this.setNext();
  }

  public checkAnswer(value: string) {
    this.wrongMessage = '';
    console.log(value);
    this.loading = true;
    //let x = this.http.post('http://localhost:4201/api', {
    let x = this.http.post('https://quiz.schuler-solutions.ch/check.php', {
      index: this.actualQuestIndex,
      answer: value
    });
    x.subscribe((response: any) => {
      this.loading = false;

      if (response.result) {
        this.updateIndex();
        this.setNext();
        this.answer.nativeElement.value = '';
        console.log(this.answer);
      } else {
        this.wrongMessage = 'Next try...';
      }
    });

  }

  public updateIndex() {
    ++this.actualQuestIndex;
    localStorage.setItem(this.STORAGE_INDEX_NAME, this.actualQuestIndex.toString(10));
  }


  public setNext() {
    this.text = this.quests[this.actualQuestIndex].text;
    this.question = this.quests[this.actualQuestIndex].question;
  }


  constructor(private http: HttpClient) {}
}
